import React from 'react';

const QrCode: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11 9V5H13V9H11Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 15V11H22V15H20Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 15V11H16V15H14Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 22L20 17L22 17L22 22L20 22Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 19H15V17H22V19Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 22V17H13V22H11Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 22V17H17V22H15Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 13L11 13V11H16V13Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0001 15L17.7144 15L17.7144 13L22.0001 13L22.0001 15Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 13L2 13L2 11L9 11V13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.14286H7.14286V4H4V7.14286ZM9.14286 9.14286V2H2V9.14286H9.14286Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20H7.14286V16.8572H4V20ZM9.14286 22V14.8572H2V22H9.14286Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8571 7.14286H20V4H16.8571V7.14286ZM22 9.14286V2H14.8571V9.14286H22Z"
      />
    </svg>
  );
};

export default QrCode;
